/* Navbar Styles */
.navbar {
    background: #004d9d;
    color: #ffffff;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
   
}

.navbar-heading {
    text-align: left;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap; 
    text-transform: uppercase;
    text-overflow: ellipsis;
 display: none;
}

.navbar-title {
    font-size: 1.5rem; /* Adjusted for smaller screens */
    font-weight: 700;
    margin: 0;
    white-space: nowrap; 
}

.navbar-subtitle {
    font-size: 0.7rem; 
    font-weight: 500;
    margin: 3px 0 0 0;
    white-space: wrap; 
    
  
}
.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  width: 100%;
    margin: 0 auto;
}

/* Logo */
.navbar-logo {
    display: flex;
    align-items: center;
    height: 40px;
    width: auto;
    cursor: pointer;
    padding: 5px;
    display: none;
}

/* Menu Toggle (Mobile) */
.menu-toggle {
    display: none; /* Default to hidden for desktop */
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    z-index: 1100;
}

.menu-bar {
    width: 25px;
    height: 3px;
    background-color: #ffffff;
    border-radius: 2px;
    transition: all 0.3s ease;
}

/* Toggle Open States */
.menu-bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.menu-bar.open:nth-child(2) {
    opacity: 0;
}

.menu-bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

/* Navigation Links - Default (Desktop) */
.nav-links {
    display: flex;
    list-style: none;
    gap: 25px;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1rem;
}

/* Navigation Link Styles */
.nav-link {
    color: #ffffff;
    text-decoration: none;
    position: relative;
    padding: 10px 0;
    transition: color 0.3s ease, transform 0.3s ease;
    text-align: center;
}

.nav-link:hover {
    color: #cce7f6;
}

.nav-link::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 3px;
    width: 0;
    height: 2px;
    background-color: #ffffff;
    transform: translateX(-50%);
    transition: width 0.3s ease;
    
}

.nav-link:hover::after {
    width: 100%;
}

/* Mobile Menu Styles */
@media (max-width: 768px) {
    .menu-toggle {
        display: flex; /* Show toggle for mobile */
    }

    .nav-links {
        flex-wrap: wrap;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
    background-image: url("https://res.cloudinary.com/dj9toibx5/image/upload/v1732605375/Untitled_design_10_ycds6x.png");
    background-position: center; 
    background-size: cover; 
    background-repeat: no-repeat;
        border-radius: 50%; 
        padding: 20px;
        flex-direction: column;
        gap: 15px;
        transform: scale(0); 
        opacity: 0;
        pointer-events: none;
        overflow-y: auto;
        z-index: 1000;
        transition: transform 0.6s ease, opacity 0.6s ease, border-radius 0.6s ease; 
        
    }
    
    .nav-links.open {
        transform: scale(1); /* Scale to full size */
        opacity: 1;
        pointer-events: auto;
        border-radius: 0; 
        
    }
    
    .navbar-heading {
        text-align: left;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap; 
        text-transform: uppercase;
        text-overflow: ellipsis;
     display: block;
    }
    
    .navbar-title {
        font-size: 4.5vw; 
        font-weight: 700;
        margin: 0;
        white-space: nowrap; 
    }
    
    .navbar-subtitle {
        font-size: 0.5rem; 
        font-weight: 500;
        margin: 3px 0 0 0;
        white-space: wrap; 
        
      
    }
    .navbar-logo {
        display: flex;
        align-items: center;
        height: 40px;
        width: auto;
        cursor: pointer;
        padding: 5px ;
        margin-left: -15px;
        margin-right: 7px;
        
    }
}

/* Desktop Styles (Default Behavior for Large Screens) */
@media (min-width: 768px) {
    .menu-toggle {
        display: none; 
    }

    .nav-links {
        display: flex        ;
                flex-wrap: wrap;
                position: static;
                transform: none;
                opacity: 1;
                flex-direction: row;
                gap: 15px;
                line-height: 12px;
                pointer-events: auto;
    }
    
}
