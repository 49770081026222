/* Modal Container */
.upload-modal-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  /* Heading */
  .upload-modal-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Upload Form */
  .upload-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  
  .upload-form input[type="file"] {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .upload-form button {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .upload-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Uploaded Image Section */
  .uploaded-images {
    margin-top: 20px;
  }
  
  .modal-item-u {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
  }
  
  .modal-image-u {
    max-width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .delete-button {
    padding: 5px 15px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
    align-self: flex-end;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  
  /* Responsive Design */
  @media (max-width: 480px) {
    .upload-modal-container {
      padding: 15px;
    }
  
    .upload-form button,
    .delete-button {
      font-size: 12px;
    }
  }
  