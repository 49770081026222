.facts-container {
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 40px; 
    background-color: #004d9d;
    flex-wrap: wrap;
    gap: 20px;
}

.countdown-fact {
    text-align: center; 
}

.fact-title {
    font-size: 18px; 
    color: #08628f; 
    font-weight: bold; 
    margin: 5px 0;
}

.fact-number {
    font-size: 48px; 
    color: #eb951a; 
    font-weight: bold; 
    margin: 5px 0;
    transition: all 0.5s ease-in-out; 
}

.fact-subheading {
    font-size: 14px;
    color: #555;
    margin-top: 5px;
}

.fact-icon {
    font-size: 40px;
    color: #08628f;
    margin-bottom: 10px;
}

.countdown-card {
    background-color: #fff;
    max-height: 300px;
    min-height: 150px;
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 14px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.countdown-card:hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.45) 0px 8px 20px; 
}

@media (max-width: 768px) {
    .facts-container {
        flex-direction: column;
        padding: 20px; 
    }

    .fact-title {
        font-size: 16px; 
    }

    .fact-number {
        font-size: 36px; 
    }
}
