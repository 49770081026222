/* GrievanceSection.css */
.grievance-section {
    background-image: url('https://res.cloudinary.com/dj9toibx5/image/upload/v1732603273/4_yju61h.webp'); 
    background-size: cover; 
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-position: center;
    min-height: 100vh; 
    overflow-y: auto;
    padding: 20px;
    color: #333; 
  }
  
  .grievance-hero {
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    background: rgba(0, 0, 0, 0.5); /* Optional: Adds contrast to the text */
    padding: 20px;
    border-radius: 8px;
  }
  
  .grievance-title {
    font-size: 2.5rem;
    margin: 0;
  }
  
  .grievance-description {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .grievance-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.9); /* Slightly transparent background */
    border-radius: 8px;
    overflow: hidden;
  }
  
  .grievance-table th, .grievance-table td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .grievance-table th {
    background-color: #004d9d;
    color: white;
  }
  
  .grievance-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .grievance-table tr:hover {
    background-color: #ddd;
  }
  