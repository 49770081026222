/* Overlay with blurred background */
.modal-overlay {
    position: fixed;
    inset: 0; /* shorthand for top, right, bottom, left: 0 */
    background: rgba(0, 0, 0, 0.75); /* Slightly darker overlay for a professional look */
    backdrop-filter: blur(8px); /* Increased blur for better focus on modal */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden; /* Prevent background scrolling */
}

/* Modal container */
.modal-container {
    background: #ffffff; /* Neutral white background */
    border-radius: 10px; /* Softer rounded corners */
    padding: 0; /* Remove unnecessary padding */
    max-width: 90%; /* Make it responsive */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* More prominent shadow for a modern look */
    position: relative;
    animation: fadeIn 0.4s ease; /* Smooth animation when modal appears */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Close button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    color: #f73333;
    cursor: pointer;
    z-index: 1001;
    transition: color 0.2s ease;
}

.close-button:hover {
    color: #000; /* Darker color on hover for better visibility */
}

/* Image inside modal */
.modal-image {
    max-width: 100%;
    max-height: 90vh; /* Restrict height to viewport height */
    object-fit: contain; /* Ensure the image scales properly without cropping */
    border-radius: 10px;
    margin: 0;
}

/* Image title */
.modal-title {
    font-size: 16px;
    font-weight: 500;
    color: #444;
    margin: 8px 0;
    text-align: center;
}

/* Responsive Design */
@media (max-width: 480px) {
    .close-button {
        top: 5px;
        right: 5px;
        font-size: 20px;
        color: #f73333; 
    }

    .modal-title {
        font-size: 14px;
        padding: 10px;
    }
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
