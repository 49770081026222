/* Carousel container styling */
.carousel-container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
   height: 100vh;
  }
  
  .carousel-slide {
    position: relative;
  }
  
  .carousel-slide img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  
  .carousel-text-overlay {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    text-align: left;
    padding: 20px;
    border-radius: 10px;
    width: 40%;
  }
  
  
  .carousel-text-overlay.left {
    left: 10%;
  }
  
  .carousel-text-overlay.right {
    right: 10%;
    text-align: right;
  }
  
  /* Text styling */
  .carousel-text-overlay h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .carousel-text-overlay p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .carousel-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #FF7F50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .carousel-button:hover {
    background-color: #FF5722;
  }
  
  /* Slick dots styling */
  .slick-dots {
    bottom: 20px;
  }
  
  .slick-dots li button:before {
    font-size: 12px;
    color: #ddd;
  }
  
  .slick-dots li.slick-active button:before {
    color: #FF7F50;
  }
  
  /* Arrow styles */
  .slick-prev, .slick-next {
    top: 50%;
    z-index: 1;
  
    border-radius: 50%;
    padding: 10px;
  }
  
  
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  
  
  @media (max-width: 768px) {
    .carousel-container {
      max-width: 100%;
    }
  
    /* Hide the navigation arrows on mobile */
    .slick-prev, .slick-next {
      display: none;
    }
  
    /* Update text overlay positioning for mobile */
    .carousel-text-overlay {
      top: 10%; 
      transform: translateY(0); 
      width: 100%; 
      text-align: left; 
      
  
    }
  
    .carousel-text-overlay h2 {
      font-size: 24px; /* Smaller heading for mobile */
      margin-bottom: 10px;
    }
  
    .carousel-text-overlay p {
      font-size: 14px; /* Smaller text size for mobile */
      margin-bottom: 15px;
    }
  
    .carousel-button {
      font-size: 14px;
      padding: 8px 16px;
    }
    .carousel-text-overlay.left {
      left: 0%;
    }
    
    .carousel-text-overlay.right {
      right: 0%;
      text-align: right;
    }
    
  }
  