.chairman-message-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    background-color: #f9f9f9;
    gap: 20px;
}

.chairman-image {
    flex: 1;
    max-width: 500px;
}

.chairman-photo {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
}

.chairman-text {
    flex: 1.5;
    max-width: 600px;
    text-align: left;
}

.chairman-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #004d9d;
}

.chairman-message-paragraph {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
    color: #555;
    transition: opacity 0.5s ease; /* Smooth transition for fallback */
}

.chairman-author {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-top: 20px;
}

.chairman-title {
    font-size: 1rem;
    font-weight: 500;
    color: #666;
    margin-top: 5px;
}

.chairman-fade-text {
    opacity: 0; /* Start invisible for GSAP animation */
}

@media (max-width: 768px) {
    .chairman-message-container {
        flex-direction: column;
        padding: 20px;
    }

    .chairman-photo {
        margin-bottom: 20px;
    }

    .chairman-heading {
        font-size: 2rem;
    }

    .chairman-message-paragraph {
        font-size: 1rem;
    }

    .chairman-author {
        font-size: 1.2rem;
    }

    .chairman-title {
        font-size: 0.9rem;
    }
}
