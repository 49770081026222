body {
    margin: 0;
    font-family: Arial, sans-serif;
    overflow-x: hidden;
  }
  
  .unique-features-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .heading {
    text-align: center;
    margin-top: 20px;
    font-size: 2rem;
    color: #333;
  }
  
  .subheading {
    text-align: center;
    margin-bottom: 40px;
    font-size: 1.2rem;
    color: #555;
  }
  
  .unique-feature-wrapper {
    display: flex;
    gap: 20px; 
    flex-wrap: nowrap;
    width: fit-content;
  }
  
  .unique-feature-box {
    flex: 0 0 33.333%; 
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  
  .feature-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .unique-feature-box:hover .feature-image {
    transform: scale(1.1);
  }
  
  .feature-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .unique-feature-box:hover .feature-overlay {
    opacity: 1; 
  }
  
  .feature-title {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 1rem;
    margin: 0;
    color: rgb(10, 6, 235);
  }
  
  .unique-feature-box:hover .feature-title {
    position: static;
    font-size: 1.5rem;
  }
  
  .feature-description {
    font-size: 1rem;
    padding: 0 10px;
    text-align: center;
  }
  

  
 
  @media (max-width: 1024px) {
    .unique-feature-box {
      flex: 0 0 50%; 
    }
  }
  

  @media (max-width: 768px) {
    .unique-feature-wrapper {
      display: flex;
      gap: 10px;
      
      width: 100%;
      padding: 10px;
    }
  
    .unique-feature-box {
      flex: 0 0 80%;
      margin-right: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .unique-feature-box {
      flex: 0 0 90%; 
    }
  }
  