/* Container styling */
.login-register-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    background: linear-gradient(to bottom, #f8f9fa, #e9ecef);
    font-family: "Poppins", sans-serif;
  }
  
  /* Form section */
  .login-register-form-container {
    flex: 1;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .login-register-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .login-register-form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
  }
  
  .login-register-input {
    margin-bottom: 1rem;
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .login-register-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
  }
  
  .login-register-button {
    padding: 0.8rem;
    font-size: 1rem;
    color: white;
    background: linear-gradient(to right, #007bff, #0056b3);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .login-register-button:hover {
    background: linear-gradient(to right, #0056b3, #003d80);
  }
  
  .login-register-message {
    color: #e63946;
    margin-top: 1rem;
    text-align: center;
  }
  
  .login-register-forgot-password {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  
  .login-register-toggle {
    margin-top: 2rem;
    font-size: 0.9rem;
    text-align: center;
  }
  
  .login-register-switch {
    color: #007bff;
    cursor: pointer;
    font-weight: 600;
  }
  
  /* Image section */
  .login-register-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #f1f3f5, #dee2e6);
  }
  
  .login-register-image {
    width: 80%;
    max-width: 500px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive design for mobile */
  @media (max-width: 768px) {
    .login-register-container {
      flex-direction: column;
    }
  
    .login-register-image-container {
      order: 2;
    }
  
    .login-register-form-container {
      order: 1;
    }
  }
  