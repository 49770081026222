/* Container for the upload form and images */
.upload-gallery-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* Form elements for uploading */
  .upload-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .upload-form input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .upload-form button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .upload-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .upload-form button:hover:enabled {
    
  }
  
  /* Styling for the gallery of uploaded images */
  .uploaded-images {
    margin-top: 20px;
  }
  
  .gallery-u {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
  }
  
  .gallery-item-u {
    position: relative;
    border: 2px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease, border 0.3s ease;
  }
  
  .gallery-item-u:hover {
    transform: scale(1.05);
    border-color: #007bff;
  }
  
  .gallery-image-u {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .gallery-title-u{
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 123, 255, 0.7);
    color: white;
    padding: 10px;
    font-size: 1.1rem;
    width: 100%;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .gallery-item-u:hover .gallery-title-u {
    background-color: rgba(0, 123, 255, 1);
    transform: translateY(-100%);
  }
  
  /* Delete button styling */
  .delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }
  
  .delete-button:hover {
    opacity: 1;
  }
  
  .delete-button:focus {
    outline: none;
  }
  
  /* Toast container */
  .Toastify__toast-container {
    z-index: 9999;
  }
  