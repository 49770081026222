.AllEOA-section {
    background-image: url('https://res.cloudinary.com/dj9toibx5/image/upload/v1732603268/7_oljuol.webp'); 
    background-size: cover; 
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-position: center;
    min-height: 100vh; 
    overflow-y: auto;
    padding: 20px;
    color: #333; 
  }