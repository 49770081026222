.notice-container-unique {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .notice-form-unique {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .notice-input-unique,
  .notice-submit-btn-unique {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .notice-submit-btn-unique {
    background-color: #004d9d;
    color: #fff;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }
  
  .notice-submit-btn-unique:hover {
    background-color: #eb951a;
  }
  
  .notice-list-unique {
    margin-top: 20px;
  }
  
  .notice-item-unique {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 10px;
  }
  
  .notice-title-unique {
    font-weight: bold;
    color: #004d9d;
  }
  
  .notice-actions-unique button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .edit-btn-unique:hover {
    color: #004d9d;
  }
  
  .delete-btn-unique:hover {
    color: red;
  }
  
  .notice-file-unique {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .notice-input-unique {
    font-size: 16px;
  }
  