.scholarship-banner-container {
    position: relative;
    width: 90%;
    margin-left: 5%;
   margin-top: 30px;
   margin-bottom: 30px;
    height: 80vh; 
    background-image: url('https://res.cloudinary.com/dj9toibx5/image/upload/v1733728678/arsh_ofnlga.webp');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end; 
    justify-content: flex-start;
    overflow: hidden;
    border-radius: 10px;
}

.scholarship-banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 77, 157, 0.7); /* Blue overlay */
    z-index: 1;
    overflow: hidden;
}

.scholarship-bubbles {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.scholarship-bubble {
    position: absolute;
    bottom: -150px;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    animation: scholarship-float 6s infinite;
    opacity: 0.6;
}

.scholarship-bubble:nth-child(1) {
    left: 10%;
    animation-duration: 5s;
    animation-delay: 0s;
    width: 25px;
    height: 25px;
}

.scholarship-bubble:nth-child(2) {
    left: 20%;
    animation-duration: 6s;
    animation-delay: 2s;
    width: 30px;
    height: 30px;
}

.scholarship-bubble:nth-child(3) {
    left: 30%;
    animation-duration: 7s;
    animation-delay: 4s;
    width: 15px;
    height: 15px;
}

.scholarship-bubble:nth-child(4) {
    left: 50%;
    animation-duration: 8s;
    animation-delay: 1s;
    width: 40px;
    height: 40px;
}

.scholarship-bubble:nth-child(5) {
    left: 70%;
    animation-duration: 5.5s;
    animation-delay: 3s;
    width: 20px;
    height: 20px;
}

.scholarship-bubble:nth-child(6) {
    left: 80%;
    animation-duration: 6.5s;
    animation-delay: 5s;
    width: 35px;
    height: 35px;
}

@keyframes scholarship-float {
    0% {
        transform: translateY(0) scale(1);
        opacity: 0.8;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateY(-800px) scale(0.5);
        opacity: 0;
    }
}

.scholarship-banner-content {
    position: relative;
    z-index: 2; /* Ensure it is above the overlay */
    padding: 20px;
    color: white;
    text-align: left;
}

.scholarship-banner-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0 0 10px 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.scholarship-banner-subheading {
    font-size: 1.2rem;
    margin: 0 0 20px 0;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.scholarship-banner-button {
    padding: 10px 20px;
    background-color: #eb951a;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.scholarship-banner-button:hover {
    background-color: #fc9506;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .scholarship-banner-container {
        height: 60vh; /* Adjust height for smaller screens */
    }

    .scholarship-banner-heading {
        font-size: 2rem;
    }

    .scholarship-banner-subheading {
        font-size: 1rem;
    }

    .scholarship-banner-button {
        font-size: 0.9rem;
    }
}
