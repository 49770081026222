.notice-board {
    position: relative;
    overflow: hidden;
    height: 200px; /* Adjust height as needed */
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  
  .notice-board:hover .notice-container {
    animation-play-state: paused; /* Pause animation on hover */
  }
  
  .notice-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    animation: marquee 10s linear infinite;
  }
  
  .notice-item {
    margin: 10px 0;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
  }
  
  .notice-item:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .notice-heading {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .new-badge {
    color: red;
    font-size: 12px;
    margin-left: 5px;
  }
  
  @keyframes marquee {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  