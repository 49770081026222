/* Container Styles */
.grievance-upload-container {
    max-width: 800px;
    margin: 0 auto;
    background: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Title */
  .grievance-upload-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
    font-weight: 600;
    color: #333;
  }
  
  /* Upload Form */
  .grievance-upload-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .grievance-upload-label {
    font-size: 1rem;
    color: #444;
    font-weight: 500;
  }
  
  .grievance-upload-file-input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    cursor: pointer;
  }
  
  .grievance-upload-button {
    padding: 12px 20px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .grievance-upload-button:hover {
    background-color: #0056b3;
  }
  
  .grievance-upload-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Files List */
  .grievance-upload-file-list {
    margin-top: 40px;
  }
  
  .grievance-upload-file-list-title {
    font-size: 1.5rem;
    color: #444;
    margin-bottom: 15px;
  }
  
  .grievance-upload-file-list-items {
    list-style: none;
    padding: 0;
  }
  
  .grievance-upload-file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #f9f9f9;
    transition: transform 0.2s;
  }
  
  .grievance-upload-file-item:hover {
    transform: translateY(-5px);
  }
  
  .grievance-upload-file-name {
    font-size: 1rem;
    color: #333;
    font-weight: 500;
  }
  
  .grievance-upload-file-actions {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .grievance-upload-download-link {
    text-decoration: none;
    font-size: 0.9rem;
    color: #007bff;
  }
  
  .grievance-upload-download-link:hover {
    text-decoration: underline;
  }
  
  .grievance-upload-delete-button {
    padding: 8px 15px;
    background-color: #ff4d4f;
    color: white;
    font-size: 0.9rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .grievance-upload-delete-button:hover {
    background-color: #d9363e;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .grievance-upload-container {
      padding: 20px;
    }
  
    .grievance-upload-title {
      font-size: 1.5rem;
    }
  
    .grievance-upload-form {
      gap: 10px;
    }
  
    .grievance-upload-button {
      width: 100%;
    }
  }
  